<template>
    <div class="selectField">
        <div class="selectField__header">
            <label :for="`${id}-states`"
                class="selectField__label"
                :class="{selectField__label_disabled: disabled}">
                {{label}} {{ required ? '*' : '' }}
            </label>
            <app-tooltip v-if="tooltipMessage" :tooltipMessage="tooltipMessage"></app-tooltip>
        </div>
        <select :id="`${id}-states`"
            class="selectField__select"
            :class="{ selectField__select_error: mutableError }"
            :disabled="disabled"
            v-model="selectedValue"
            >
            <option v-if="!required" value="null"></option>
            <option v-for="option in options" :key="`${id}-${option.value}`" :value="option.value">
                {{option.label}}
            </option>
        </select>
        <sf-loader v-if="loading" class="selectField__loader"></sf-loader>
        <span v-if="mutableError" class="selectField__error">{{mutableError}}</span>
    </div>
</template>

<script>
import appTooltip from './tooltip';
import sfLoader from './loader';

export default {
    name: 'sf-select',
    components: {
        appTooltip,
        sfLoader
    },
    props: {
        id: String,
        label: String,
        options: Array,
        required: Boolean,
        disabled: Boolean,
        loading: Boolean,
        tooltipMessage: String,
        error: String,
        value: String
    },
    computed: {
        selectedValue: {
            get () {
                return this.value;
            },
            set (val) {
                if (this.mutableError) this.mutableError = null;
                this.$emit('input', val);
            }
        }
    },
    data: function () {
        return {
            // selectedValue: this.value,
            mutableError: null
        };
    },
    methods: {
        // onChange: function () {
        //     if (this.mutableError) this.mutableError = null;
        //     this.$emit('input', this.selectedValue);
        // }
    },
    watch: {
        error (newValue) {
            this.mutableError = newValue;
        }
    }
};
</script>
