import validator from './validators';

const validatorsMap = {
    'one-line-input': validator.validateOneLineInput,
    'multiline-input': validator.validateOneLineInput,
    email: validator.validateEmail,
    'phone-number': validator.validatePhoneNumber,
    date: validator.validateDate,
    number: validator.validateNumber,
    'multi-select': validator.validateMultiselect,
    select: validator.validateSelect,
    years: validator.validateSelect,
    states: validator.validateSelect,
    'radio-group': validator.validateRadioGroup,
    'zip-field': validator.validateZipCode
};

export default function (formElements) {
    let isFormValid = true;
    formElements.forEach(row => {
        row.forEach(element => {
            const validatorFunction = validatorsMap[element.content.type];
            if (validatorFunction) {
                const fieldError = validatorFunction(element.content);
                if (fieldError) {
                    element.content.props.error = fieldError;
                    isFormValid = false;
                }
            }
        });
    });
    return { isFormValid, formElements };
};
