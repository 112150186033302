<template>
    <div class="inputField">
        <div class="inputField__header">
            <label :for="`${id}-text-field`"
                class="inputField__label"
                :class="{inputField__label_disabled: disabled}">
                {{label}} {{ required ? '*' : '' }}
            </label>
            <app-tooltip v-if="tooltipMessage" :tooltipMessage="tooltipMessage" class="inputField__tooltip"></app-tooltip>
            <span v-if="maxlength && isCounterShown" class="inputField__counter"
                :class="{ inputField__counter_warning: characterNumber > warningCharacterNumber}">
                {{characterNumber}}<span class="inputField__counter_max">/{{maxlength}}</span>
            </span>
        </div>
        <input type="text"
            class="inputField__input"
            :class="{ inputField__input_error: mutableError }"
            :id="`${id}-text-field`"
            :disabled="disabled"
            :placeholder="placeholder"
            :maxlength="maxlength"
            :minlength="minlength"
            v-model="mutableValue"
            @blur="validateInput"
        >
        <sf-loader v-if="loading" class="inputField__loader"></sf-loader>
        <span v-if="mutableError" class="inputField__error">{{mutableError}}</span>
    </div>
</template>

<script>
import appTooltip from './tooltip';
import sfLoader from './loader';
import validator from '../../validators.js';

export default {
    name: 'sf-one-line-input',
    components: {
        appTooltip,
        sfLoader
    },
    props: {
        id: String,
        label: String,
        placeholder: String,
        required: Boolean,
        disabled: Boolean,
        loading: Boolean,
        tooltipMessage: String,
        maxlength: Number,
        minlength: Number,
        isCounterShown: Boolean,
        error: String,
        value: String
    },
    data: function () {
        return {
            mutableError: null
        };
    },
    computed: {
        mutableValue: {
            get () {
                return this.value;
            },
            set (val) {
                if (this.mutableError) this.mutableError = null;
                this.$emit('input', val);
            }
        },
        characterNumber: function () {
            return this.mutableValue?.length || 0;
        },
        warningCharacterNumber: function () {
            return this.maxlength * 0.96;
        }
    },
    methods: {
        validateInput: function () {
            const error = validator.validateOneLineInput({
                value: this.mutableValue,
                props: {
                    required: this.required,
                    minlength: this.minlength,
                    maxlength: this.maxlength
                }
            });

            this.mutableError = error;
            if (error) {
                this.$emit('error', error);
            }
        }
    },
    watch: {
        error (newValue) {
            this.mutableError = newValue;
        }
    }
};
</script>
