<template>
    <div class="inputField">
        <div class="inputField__header">
            <label :for="`${id}-text-field`" class="inputField__label">{{label}} {{ required ? '*' : '' }}</label>
            <app-tooltip v-if="tooltipMessage" :tooltipMessage="tooltipMessage" class="inputField__tooltip"></app-tooltip>
        </div>
        <input type="text"
            class="inputField__input"
            :class="{ inputField__input_error: mutableError }"
            :id="`${id}-zip-code`"
            :placeholder="placeholder"
            :maxlength="maxlength"
            v-model="mutableValue"
            @input="$event => limitCharacters($event.target.value)"
            @blur="validateInput"
        >
        <span v-if="mutableError" class="inputField__error">{{mutableError}}</span>
    </div>
</template>

<script>
import appTooltip from './tooltip';
import validator from '../../validators.js';

export default {
    name: 'sf-zip',
    components: {
        appTooltip
    },
    props: {
        id: String,
        label: String,
        placeholder: String,
        required: Boolean,
        tooltipMessage: String,
        error: String,
        value: String
    },
    data: function () {
        return {
            mutableError: null
        };
    },
    computed: {
        mutableValue: {
            get () {
                return this.value;
            },
            set (val) {
                if (this.mutableError) this.mutableError = null;
                this.$emit('element-event', 'zip-change', { value: val, id: this.id });
                this.$emit('input', val);
            }
        }
    },
    methods: {
        limitCharacters: function (value) {
            if (value && value.length > 5) {
                this.mutableValue = value.slice(0, 5);
            }
        },
        validateInput: function () {
            let error = null;
            if (this.error !== 'Not found.') {
                error = validator.validateZipCode({
                    value: this.mutableValue,
                    props: {
                        required: this.required
                    }
                });

                this.mutableError = error;
            }

            if (error) {
                this.$emit('error', error);
            }
        }
    },
    watch: {
        error (newValue) {
            this.mutableError = newValue;
        }
    }
};
</script>

<style scoped>
</style>
