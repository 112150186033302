<template>
    <div class="inputField">
        <div class="inputField__header">
            <label :for="`${id}-textarea`" class="inputField__label">{{label}} {{ required ? '*' : '' }}</label>
            <app-tooltip v-if="tooltipMessage" :tooltipMessage="tooltipMessage"></app-tooltip>
            <span v-if="maxlength" class="inputField__counter"
                :class="{ inputField__counter_warning: characterNumber > warningCharacterNumber}">
                {{characterNumber}}<span class="inputField__counter_max">/{{maxlength}}</span>
            </span>
        </div>
        <textarea :maxlength="maxlength" :id="`${id}-textarea`"
            class="inputField__input"
            :class="{ inputField__input_error: mutableError }"
            :placeholder="placeholder"
            :rows="rows"
            v-model="mutableValue"
            @blur="validateInput">
        </textarea>
        <span v-if="mutableError" class="inputField__error">{{mutableError}}</span>
    </div>
</template>

<script>
import appTooltip from './tooltip';
import validator from '../../validators.js';

export default {
    name: 'sf-multiline-input',
    components: {
        appTooltip
    },
    props: {
        id: String,
        label: String,
        placeholder: String,
        required: Boolean,
        tooltipMessage: String,
        rows: Number,
        maxlength: Number,
        error: String,
        value: String
    },
    data: function () {
        return {
            mutableError: null
        };
    },
    computed: {
        mutableValue: {
            get () {
                return this.value;
            },
            set (val) {
                if (this.mutableError) this.mutableError = null;
                this.$emit('input', val);
            }
        },
        characterNumber: function () {
            return this.mutableValue?.length || 0;
        },
        warningCharacterNumber: function () {
            return this.maxlength * 0.96;
        }
    },
    methods: {
        validateInput: function () {
            const error = validator.validateOneLineInput({
                value: this.mutableValue,
                props: {
                    required: this.required
                }
            });

            this.mutableError = error;
            if (error) {
                this.$emit('error', error);
            }
        }
    },
    watch: {
        error (newValue) {
            this.mutableError = newValue;
        }
    }
};
</script>
