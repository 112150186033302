<template>
    <div>
        <label :for="`${id}-image-field`">{{label}}</label>
        <input :id="`${id}-image-field`" type="file" :accept="accept" >
    </div>
</template>

<script>

export default {
    name: 'sf-image-uploader',
    props: {
        id: String,
        label: String,
        accept: {
            type: String,
            default: 'image/jpeg,image/png'
        }
    }
};
</script>
