<template>
    <div class="inputField">
        <label :for="`${id}-number-field`" class="inputField__label">{{label}} {{ required ? '*' : '' }}</label>
        <app-tooltip v-if="tooltipMessage" :tooltipMessage="tooltipMessage"></app-tooltip>
        <input :id="`${id}-number-field`" type="number"
            class="inputField__input"
            :class="{ inputField__input_error: mutableError }"
            :placeholder="placeholder"
            :min="minValue" :max="maxValue"
            v-model.number="mutableValue"
            @input="$event => limitCharacters($event.target.value)"
            @blur="$event => validateInput($event.target.value)"
        >
        <span v-if="mutableError" class="inputField__error">{{mutableError}}</span>
    </div>
</template>

<script>
import appTooltip from './tooltip';
import validator from '../../validators.js';

export default {
    name: 'sf-number',
    components: { appTooltip },
    props: {
        id: String,
        label: String,
        placeholder: String,
        required: Boolean,
        tooltipMessage: String,
        maxValue: Number,
        minValue: Number,
        maxlength: Number,
        error: String,
        value: Number
    },
    data: function () {
        return {
            mutableError: null
        };
    },
    computed: {
        mutableValue: {
            get () {
                return this.value;
            },
            set (val) {
                if (this.mutableError) this.mutableError = null;
                this.$emit('input', val || null);
            }
        }
    },
    methods: {
        limitCharacters: function (value) {
            if (value && this.maxlength && value.length > this.maxlength) {
                this.mutableValue = parseInt(value.slice(0, this.maxlength));
            }
        },
        validateInput: function () {
            const error = validator.validateNumber({
                value: this.mutableValue,
                props: {
                    required: this.required,
                    maxValue: this.maxValue,
                    minValue: this.minValue
                }
            });

            this.mutableError = error;
            if (error) {
                this.$emit('error', error);
            }
        }
    },
    watch: {
        error: function (newValue) {
            this.mutableError = newValue;
        }
    }
};
</script>
