<template>
    <div class="text">
        <h1 v-if="title && titleLevel === 'h1'" class="heading_1">{{title}}</h1>
        <h2 v-if="title && titleLevel === 'h2'" class="heading_2">{{title}}</h2>
        <h2 v-if="title && !titleLevel" class="heading_2">{{title}}</h2>
        <span v-if="subTitle" class="subTitle">{{subTitle}}</span>
        <div v-if="text" class="plainText">{{text}}</div>
    </div>
</template>

<script>
export default {
    name: 'sf-static-text',
    props: {
        title: String,
        titleLevel: String,
        subTitle: String,
        text: String
    }
};
</script>
