<template>
    <form-grid :grid="form_elements" :gridColumns="grid_columns_ratio" :config="config"></form-grid>
</template>

<script>
import formGrid from './components/form-grid.vue';

export default {
    name: 'smart-form',
    components: {
        formGrid
    },
    props: {
        form_elements: Array,
        grid_columns_ratio: Array,
        config: Object
    }
};
</script>
