<template>
  <div class="checkbox">
    <input
        type="checkbox"
        :id="`${id}-checkbox`"
        class="checkbox__input"
        v-model="mutableChecked"
    />
    <label :for="`${id}-checkbox`" class="checkbox__label">{{ label }}</label>
  </div>
</template>

<script>
export default {
    name: 'sf-checkbox',
    props: {
        id: String,
        label: String,
        checked: {
            type: [Array, Boolean],
            default: false
        },
        value: {
            default: null
        },
        tooltipMessage: String
    },
    computed: {
        mutableChecked: {
            get () {
                return this.checked;
            },
            set (val) {
                this.$emit('input', val);
            }
        }
    }
};
</script>
