<template>
    <div class="selectField">
        <div class="selectField__header">
            <label :for="`${id}-select`" class="selectField__label">{{label}} {{ required ? '*' : '' }}</label>
            <app-tooltip v-if="tooltipMessage" :tooltipMessage="tooltipMessage"></app-tooltip>
        </div>
        <select :id="`${id}-select`"
            class="selectField__select"
            :class="{ selectField__select_error: mutableError }"
            v-model="selectedValue"
            @change="onChange">
            <option v-if="!required" value="null"></option>
            <option v-for="option in options" :key="`${id}-${option.value}`" :value="option.value">
                {{option.label}}
            </option>
        </select>
        <span v-if="mutableError" class="selectField__error">{{mutableError}}</span>
    </div>
</template>

<script>
import appTooltip from './tooltip';

export default {
    name: 'sf-select',
    components: {
        appTooltip
    },
    props: {
        id: String,
        label: String,
        options: Array,
        required: Boolean,
        tooltipMessage: String,
        error: String,
        modelValue: String
    },
    data: function () {
        return {
            selectedValue: this.modelValue,
            mutableError: null
        };
    },
    methods: {
        onChange: function () {
            if (this.mutableError) this.mutableError = null;
            this.$emit('input', this.selectedValue);
        }
    },
    watch: {
        error (newValue) {
            this.mutableError = newValue;
        }
    }
};
</script>
