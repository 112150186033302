<template>
    <div class="tooltipBox">
        <span class="tooltip__anchor">i</span>
        <div class="tooltip">{{tooltipMessage}}</div>
    </div>
</template>

<script>
export default {
    name: 'sf-tooltip',
    components: {

    },
    data () {
        return {

        };
    },
    props: {
        tooltipMessage: String
    },
    methods: {

    }
};
</script>
