export default {
    validateOneLineInput: function (textField) {
        const value = textField.value;
        const { required, minlength, maxlength } = textField.props;

        if (required && !value) {
            return 'This field is required';
        };

        if (maxlength && value?.length > maxlength) {
            return `The maximum length is ${maxlength} characters.`;
        };

        if (minlength && value?.length < minlength) {
            return `The minimum length is ${minlength} characters.`;
        }

        return null;
    },
    validateEmail: function (textField) {
        const value = textField.value;
        const required = textField.props.required;

        if (required && !value) {
            return 'This field is required.';
        };

        if (value && (!value.includes('@') || !value.includes('.'))) {
            return 'Please enter a valid email address.';
        }

        return null;
    },
    validatePhoneNumber: function (textField) {
        const value = textField.value;
        const required = textField.props.required;

        if (required && !value) {
            return 'This field is required.';
        };

        if (value && value.length < 14) {
            return 'Please enter a valid phone number.';
        }

        return null;
    },
    validateDate: function (dateField) {
        const value = dateField.value;
        const required = dateField.props.required;

        if (required && !value) {
            return 'This field is required.';
        };

        return null;
    },
    validateNumber: function (numberField) {
        const value = numberField.value;
        const { required, maxValue, minValue } = numberField.props;

        if (required && !value) {
            return 'This field is required.';
        };

        if (maxValue && value && value > maxValue) {
            return `The maximum value is ${maxValue}.`;
        };

        if (minValue && value && value < minValue) {
            return `The minimum value is ${minValue}.`;
        }

        return null;
    },
    validateMultiselect: function (multiselect) {
        const value = multiselect.value;
        const required = multiselect.props.required;

        if (required && !value?.length) {
            return 'Select at least 1 option.';
        };

        return null;
    },
    validateSelect: function (select) {
        const value = select.value;
        const required = select.props.required;

        if (required && !value) {
            return 'This field is required.';
        };

        return null;
    },
    validateRadioGroup: function (radioGroup) {
        const value = radioGroup.value;
        const required = radioGroup.props.required;

        if (required && !value) {
            return 'This field is required.';
        };

        return null;
    },

    validateZipCode: function (zipField) {
        const value = zipField.value;
        const required = zipField.props.required;

        if (required && !value) {
            return 'This field is required.';
        };

        if (value && value.length < 5) {
            return 'Please enter a valid zip code.';
        }

        return null;
    }
};
