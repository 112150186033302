<template>
  <div class="form-grid" :style="gridVariables">
    <div  v-for="(row, rowIndex) in grid" :key="`row-${rowIndex}`" class="grid-row">
      <div v-for="(cell, cellIndex) in row"
        :key="`cell-${rowIndex}-${cellIndex}`"
        style="display: contents">
        <component v-if="cell.content.type !== 'placeholder'"
          :id="cell.id"
          class="grid-cell"
          :style="{gridArea: cell.id}"
          v-model="cell.content.value"
          :is="getComponentName(cell.content.type)"
          v-bind="cell.content.props"
          @input="$event => cell.content.value = $event"
          @element-event="elementEvent"
          @error="$event => cell.content.props.error = $event"
          v-show="hiddenElementIds.indexOf(cell.id) === -1"
        >
          {{ cell.content }}
        </component>
        <div v-else-if="anchorsValueMap[cell.content.props.anchorId] && getFormSnippet(cell.content.props.formSnippets, cell.content.props.anchorId) !== null"
          :style="{gridArea: cell.id}">
          <smart-form-renderer
            :isChild="true"
            :gridColumns="gridColumns"
            :grid="getFormSnippet(cell.content.props.formSnippets, cell.content.props.anchorId)"
            :style="{gridColumn: `1 / span ${gridColumns.length};`}"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import appButton from '../form-elements/button';
import sfOneLineInput from './form-elements/one-line-input';
import sfMultilineInput from './form-elements/multiline-input';
import sfZip from './form-elements/zip';
import sfString from './form-elements/static-text';
import sfSelect from './form-elements/select';
// import appLoader from './form-elements/loader';
import sfCheckbox from './form-elements/checkbox';
import sfRadioGroup from './form-elements/radio-group';
import sfDivider from './form-elements/divider';
import sfMultiselect from './form-elements/multiselect';
// import appFileUploader from './form-elements/file-uploader';
// import objectClonner from '../object-clonner';
// import dataService from '../data.service';
import sfNumber from './form-elements/number';
import sfImageUploader from './form-elements/image-uploader';
import sfState from './form-elements/states';
import sfYear from './form-elements/years';
import sfEmailInput from './form-elements/email';
import sfPhoneNumber from './form-elements/phone-number';
import sfDate from './form-elements/date';

export default {
    name: 'form-grid',
    props: {
        grid: {
            type: Array,
            required: true
        },
        gridColumns: {
            type: Array,
            required: true
        },
        config: {
            type: Object,
            required: false
        }
    },
    components: {
    // appButton,
        sfOneLineInput,
        sfMultilineInput,
        sfSelect,
        // appLoader,
        sfCheckbox,
        sfRadioGroup,
        sfMultiselect,
        // appFileUploader,
        sfDivider,
        sfString,
        sfNumber,
        sfState,
        sfYear,
        sfEmailInput,
        sfPhoneNumber,
        sfDate,
        sfZip
    },
    computed: {
        anchorsValueMap: function () {
            const flattenedGrid = this.grid.flat();
            const placeholders = flattenedGrid.filter(cell => cell.content.type === 'placeholder');
            const anchorIds = placeholders.map(placeholder => placeholder.content.props.anchorId);
            const anchorsArray = flattenedGrid.filter(cell => anchorIds.includes(cell.id));

            const valuesObj = Object.assign({}, ...anchorsArray.map((cell) => ({ [cell.id]: cell.content.value })));
            console.log(valuesObj);

            return valuesObj;
        },
        idToPositionMap: function () {
            const map = {};
            this.grid.forEach((row, rowIndex) => {
                row.forEach((cell, colIndex) => {
                    map[cell.id] = { row: rowIndex, col: colIndex };
                });
            });
            return map;
        },
        zipCodesBindingMap: function () {
            const bindings = {};
            this.grid.forEach((row, rowIndex) => {
                row.forEach((cell, colIndex) => {
                    if (cell.content.type === 'zip-field') {
                        bindings[cell.id] = {
                            self: { row: rowIndex, col: colIndex },
                            city: this.idToPositionMap[cell.content.props.city],
                            state: this.idToPositionMap[cell.content.props.state],
                            county: this.idToPositionMap[cell.content.props.county]
                        };
                    }
                });
            });
            return bindings;
        },
        gridVariables () {
            const gridAreas = this.grid.reduce((acc, row) => {
                const newRow = row.reduce((rowAcc, cell) => {
                    const gridAreaName = cell.id || '';
                    const colspan = cell.colspan || 1;
                    rowAcc.push(...Array(colspan).fill(gridAreaName));
                    return rowAcc;
                }, []);
                acc.push(newRow);
                return acc;
            }, []);

            const gridTemplateAreas = gridAreas.reduce((acc, row) => {
                acc.push(`"${row.join(' ')}"`);
                return acc;
            }, []).join('\n');

            const gridTemplateColumns = this.gridColumns.map(item => `minmax(0, ${item}fr)`).join(' ');

            return {
                '--grid-template-areas': gridTemplateAreas,
                '--grid-template-columns': gridTemplateColumns
            };
        },
        hiddenElementIds () {
            const hiddenIds = [];
            this.grid.forEach(row => {
                row.forEach(cell => {
                    const children = this.getChildren(cell.id);
                    children.forEach(child => {
                        if (!this.isShowChild(cell, child)) {
                            hiddenIds.push(child.id);
                        }
                    });
                });
            });

            return hiddenIds;
        }
    },
    data () {
        return {
        };
    },
    methods: {
        getComponentName: function (type) {
            const componentMap = {
                'one-line-input': sfOneLineInput,
                'multiline-input': sfMultilineInput,
                'zip-field': sfZip,
                'static-text': sfString,
                select: sfSelect,
                checkbox: sfCheckbox,
                'radio-group': sfRadioGroup,
                'multi-select': sfMultiselect,
                // 'file-uploader': appFileUploader,
                'image-uploader': sfImageUploader,
                divider: sfDivider,
                number: sfNumber,
                states: sfState,
                email: sfEmailInput,
                'phone-number': sfPhoneNumber,
                years: sfYear,
                date: sfDate
            };

            return componentMap[type] || '';
        },
        elementEvent: function (eventName, eventData) {
            switch (eventName) {
            case 'zip-change':
                this.proceedZipInput(eventData.value, eventData.id);
                break;
            case 'image-change':
                this.$emit('upload-queue-push', eventData);
                // this.uploadQueue.push(eventData);
                break;
            }
        },
        isShowChild: function (parent, child) {
            return (parent.content.value instanceof Array && parent.content.value.includes(child.content.props.showWhenParentElementValue)) ||
        child.content.props.showWhenParentElementValue === parent.content.value;
        },
        setFieldLoadingState: function (position, state) {
            this.$set(this.grid[position.row][position.col].content.props, 'disabled', state);
            this.$set(this.grid[position.row][position.col].content.props, 'loading', state);
        },
        updateFieldValue: function (position, value) {
            this.$set(this.grid[position.row][position.col].content, 'value', value);
            this.setFieldLoadingState(position, false);
        },
        proceedZipInput: function (value, id) {
            const zipComponentPosition = this.zipCodesBindingMap[id].self;
            const cityComponentPosition = this.zipCodesBindingMap[id].city;
            const stateComponentPosition = this.zipCodesBindingMap[id].state;
            const countyComponentPosition = this.zipCodesBindingMap[id].county;

            if (value.length === 5) {
                this.setFieldLoadingState(cityComponentPosition, true);
                this.setFieldLoadingState(stateComponentPosition, true);
                this.setFieldLoadingState(countyComponentPosition, true);

                const zipDecodeRequestURL = `${this.config.zipCodeDecoder}${value}`;
                console.log(zipDecodeRequestURL);

                fetch(zipDecodeRequestURL)
                    .then(res => {
                        return res.json();
                    })
                    .then(res => {
                        this.updateFieldValue(cityComponentPosition, res.place_name);
                        this.updateFieldValue(stateComponentPosition, res.admin1_code);
                        this.updateFieldValue(countyComponentPosition, res.admin2_name);
                    })
                    .catch(error => {
                        this.error = error;
                        console.error('There was a problem with zip code fetch operation:', error);
                        this.$set(this.grid[zipComponentPosition.row][zipComponentPosition.col].content.props, 'error', 'Not found.');

                        this.setFieldLoadingState(cityComponentPosition, false);
                        this.setFieldLoadingState(stateComponentPosition, false);
                        this.setFieldLoadingState(countyComponentPosition, false);
                    });
            } else {
                this.updateFieldValue(cityComponentPosition, '');
                this.updateFieldValue(stateComponentPosition, '');
                this.updateFieldValue(countyComponentPosition, '');
            };
        },
        getChildren (cellId) {
            const children = [];
            this.grid.forEach(row => {
                row.forEach(cell => {
                    if (Object.prototype.hasOwnProperty.call(cell.content.props, 'parentElementId') && cell.content.props.parentElementId === cellId) {
                        children.push(cell);
                    }
                });
            });

            return children;
        },
        getFormSnippet: function (snippets, anchorId) {
            if (snippets === undefined) {
                return null;
            }
            const snippet = snippets.find(snippet => snippet.key === this.anchorsValueMap[anchorId]);
            if (snippet) return snippet.grid;
            return null;
        }
    }
};
</script>

<style scoped>

.form-grid {
  display: grid;
  grid-template-columns: var(--grid-template-columns);
  grid-template-areas: var(--grid-template-areas);
}

@media screen and (max-width: 720px) {
    .form-grid {
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }

    .grid-cell {
      grid-area: none !important;
    }
}

.grid-row {
  display: contents;
}

</style>
