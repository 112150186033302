<template>
    <div class="inputField">
        <div class="inputField__header">
            <label :for="`${id}-tel-field`" class="inputField__label">{{label}} {{ required ? '*' : '' }}</label>
            <app-tooltip v-if="tooltipMessage" :tooltipMessage="tooltipMessage"></app-tooltip>
        </div>
        <span class="inputField__prefix">+ 1</span>
        <input type="tel"
            class="inputField__input inputField__input_tel"
            :class="{ inputField__input_error: mutableError }"
            :id="`${id}-tel-field`"
            :placeholder="placeholder"
            v-model="mutableValue"
            v-mask="'(###) ###-####'"
            @blur="validateInput"
        >
        <span v-if="mutableError" class="inputField__error">{{mutableError}}</span>
    </div>
</template>

<script>
import appTooltip from './tooltip';
import validator from '../../validators.js';
import { VueMaskDirective } from 'v-mask';

export default {
    name: 'sf-phone-number',
    components: {
        appTooltip
    },
    props: {
        id: String,
        label: String,
        placeholder: String,
        required: Boolean,
        tooltipMessage: String,
        error: String,
        value: String
    },
    computed: {
        mutableValue: {
            get () {
                return this.value;
            },
            set (val) {
                if (this.mutableError) this.mutableError = null;
                this.$emit('input', val);
            }
        }
    },
    data: function () {
        return {
            mutableError: null
        };
    },
    directives: {
        mask: VueMaskDirective
    },
    methods: {
        validateInput: function () {
            const error = validator.validatePhoneNumber({
                value: this.mutableValue,
                props: {
                    required: this.required
                }
            });

            this.mutableError = error;
            if (error) {
                this.$emit('error', error);
            }
        }
    },
    watch: {
        error (newValue) {
            this.mutableError = newValue;
        }
    }
};
</script>
