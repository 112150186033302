<template>
    <div class="inputField">
        <label :for="`${id}-date-field`" class="inputField__label">{{label}} {{ required ? '*' : '' }}</label>
        <app-tooltip v-if="tooltipMessage" :tooltipMessage="tooltipMessage"></app-tooltip>
        <input type="date"
            class="inputField__input"
            :class="{ inputField__input_error: mutableError }"
            :id="`${id}-date-field`"
            :placeholder="placeholder"
            :min="min"
            :max="max"
            v-model="mutableValue"
            @blur="validateInput"
        >
        <span v-if="mutableError" class="inputField__error">{{mutableError}}</span>
    </div>
</template>

<script>
import appTooltip from './tooltip';
import validator from '../../validators.js';

export default {
    name: 'sf-date',
    components: { appTooltip },
    props: {
        id: String,
        label: String,
        placeholder: String,
        required: Boolean,
        min: String,
        max: String,
        tooltipMessage: String,
        error: String,
        value: String
    },
    computed: {
        mutableValue: {
            get () {
                return this.value;
            },
            set (val) {
                if (this.mutableError) this.mutableError = null;
                this.$emit('input', val);
            }
        }
    },
    data: function () {
        return {
            mutableError: null
        };
    },
    methods: {
        validateInput: function () {
            const error = validator.validateDate({
                value: this.mutableValue,
                props: {
                    required: this.required
                }
            });

            this.mutableError = error;
            if (error) {
                this.$emit('error', error);
            }
        }
    },
    watch: {
        error (newValue) {
            this.mutableError = newValue;
        }
    }
};
</script>
