import smartForm from './smart-form.vue';
import validateForm from './validate.js';

function install (Vue) {
    if (install.installed) return;
    install.installed = true;
    Vue.component('smart-form', smartForm);
}

const plugin = {
    install
};

let GlobalVue = null;
if (typeof window !== 'undefined') {
    GlobalVue = window.Vue;
} else if (typeof global !== 'undefined') {
    GlobalVue = global.vue;
}
if (GlobalVue) {
    GlobalVue.use(plugin);
}

smartForm.install = install;

export { smartForm, validateForm };
