<template>
    <div class="radioGroup">
        <div class="radioGroup__header">
            <span class="radioGroup__label">{{label}} {{ required ? '*' : '' }}</span>
            <app-tooltip v-if="tooltipMessage" :tooltipMessage="tooltipMessage"></app-tooltip>
        </div>
        <div class="radioGroup__list">
            <div v-for="ragioButton in buttons" :key="`${id}-radio-button-${ragioButton.value}`"
                class="radioGroup__item radioButton">
                <input type="radio"
                class="radioButton__input"
                :value="ragioButton.value"
                :checked="ragioButton.value === modelValue"
                :id="ragioButton.value"
                :name="`${id}-radio-group`"
                @input="onInput($event)">
                <label :for="ragioButton.value"
                class="radioButton__label"
                :class="{ radioButton__label_error: mutableError }">
                {{ ragioButton.label }}
                </label>
            </div>
        </div>
    </div>
</template>

<script>
import appTooltip from './tooltip';

export default {
    name: 'sf-radio-group',
    components: {
        appTooltip
    },
    props: {
        id: String,
        label: String,
        buttons: Array,
        required: Boolean,
        tooltipMessage: String,
        error: String,
        modelValue: String
    },
    data: function () {
        return {
            mutableError: null
        };
    },
    methods: {
        onInput: function (event) {
            if (this.mutableError) this.mutableError = null;
            this.$emit('input', event.target.value);
        }
    },
    watch: {
        error: function (newError) {
            this.mutableError = newError;
        }
    }
};
</script>
