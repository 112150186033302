<script>
export default {
    name: 'sf-divider',
    props: {
        height: String
    }
};
</script>

<template>
  <div>
    <div class="divider"></div>
  </div>
</template>
