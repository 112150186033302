<template>
    <div class="multiselect">
        <div class="multiselect__header">
            <span class="multiselect__label">{{label}} {{ required ? '*' : '' }}</span>
            <app-tooltip v-if="tooltipMessage" :tooltipMessage="tooltipMessage"></app-tooltip>
        </div>
        <ul class="multiselect__list" :style="rowsNumber">
        <li v-for="(checkbox) in buttons" :key="`${id}-radio-button-${checkbox.value}`"
            class="multiselect__item">
            <app-checkbox
                :id="`${id}-${checkbox.value}`"
                :label="checkbox.label"
                :checked="isChecked(checkbox.value)"
                @input="updateSelectedList(checkbox.value, $event)"
            ></app-checkbox>
        </li>
        </ul>
        <span v-if="mutableError" class="multiselect__error">{{mutableError}}</span>
    </div>
</template>

<script>
import appTooltip from './tooltip';
import appCheckbox from './checkbox';
import validator from '../../validators.js';

export default {
    name: 'sf-multiselect',
    components: {
        appTooltip,
        appCheckbox
    },
    props: {
        id: String,
        label: String,
        buttons: Array,
        colCount: Number,
        required: Boolean,
        tooltipMessage: String,
        error: String,
        value: Array
    },
    data: function () {
        return {
            mutableError: null
        };
    },
    computed: {
        rowsNumber () {
            return {
                '--rows': Math.ceil(this.buttons.length / this.colCount)
            };
        }
    },
    methods: {
        isChecked (value) {
            if (this.value) return this.value.includes(value);
        },
        updateSelectedList (optionValue, isChecked) {
            const updatedList = this.value ? [...this.value] : [];
            if (isChecked) {
                updatedList.push(optionValue);
            } else {
                const index = updatedList.indexOf(optionValue);
                if (index !== -1) {
                    updatedList.splice(index, 1);
                }
            }

            this.$emit('input', updatedList);

            const error = validator.validateMultiselect({
                value: updatedList,
                props: {
                    required: this.required
                }
            });

            this.mutableError = error;
            if (error) {
                this.$emit('error', error);
            }
        }
    },
    watch: {
        error (newValue) {
            this.mutableError = newValue;
        }
    }
};
</script>

<style scoped>
.multiselect__list {
    list-style: none;
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(var(--rows), minmax(0, 1fr));
}

@media screen and (max-width: 720px) {
    .multiselect__list {
      display: flex;
      flex-direction: column;
    }
}
</style>
