<template>
  <div class="inputField">
    <div class="inputField__header">
        <label :for="`${id}-email-field`" class="inputField__label">{{label}} {{ required ? '*' : '' }}</label>
        <app-tooltip v-if="tooltipMessage" :tooltipMessage="tooltipMessage"></app-tooltip>
    </div>
    <input type="email"
        class="inputField__input"
        :class="{ inputField__input_error: mutableError }"
        :id="`${id}-email-field`"
        :placeholder="placeholder"
        v-model="mutableValue"
        @blur="validateInput"
    >
    <span v-if="mutableError" class="inputField__error">{{mutableError}}</span>
  </div>
</template>

<script>
import appTooltip from './tooltip';
import validator from '../../validators.js';

export default {
    name: 'sf-email',
    components: {
        appTooltip
    },
    props: {
        id: String,
        label: String,
        placeholder: String,
        required: Boolean,
        tooltipMessage: String,
        error: String,
        value: String
    },
    computed: {
        mutableValue: {
            get () {
                return this.value;
            },
            set (val) {
                if (this.mutableError) this.mutableError = null;
                this.$emit('input', val);
            }
        }
    },
    data: function () {
        return {
            mutableError: null
        };
    },
    methods: {
        validateInput: function () {
            const error = validator.validateEmail({
                value: this.mutableValue,
                props: {
                    required: this.required
                }
            });

            this.mutableError = error;
            if (error) {
                this.$emit('error', error);
            }
        }
    },
    watch: {
        error (newValue) {
            this.mutableError = newValue;
        }
    }
};
</script>
